export const trackPageView = (url) => {
	try {
		window.gtag('config', 'UA-48019163-8', {
			page_location: url
		});
	} catch (error) {
		// silences the error in dev mode
		// and/or if gtag fails to load
	}
};

export const hotJarInitial = (hjid, hjsv) => {
	try {
		hotjar.initialize(hjid, hjsv);
	} catch (error) {
		// silences the error in dev mode
		// and/or if gtag fails to load
	}
};
