import { AnimatePresence } from 'framer-motion';
import Router from 'next/router';
import { DefaultSeo } from 'next-seo';
import App from 'next/app';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import '../styles/main.scss';

import Navigation from '../components/navigation';
import { hotJarInitial, trackPageView } from '../utils/analytics';
import SEO from '../utils/next-seo.config';
import { getAdminPerson } from '../utils/contentfull';
import { useEffect } from 'react';
import { handleWebVitals, isProduction, parseSocial } from '@utils/helpers';

import * as Sentry from '@sentry/node';
import logger from '@utils/logger';
// import logger from '@utils/logger';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
	Sentry.init({
		enabled: isProduction(),
		dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
	});
}

// export function reportWebVitals(metric) {
// 	const formattedMetric = handleWebVitals(metric);
// 	const webVitals = logger.child({ web_vitals: formattedMetric })
// 	webVitals.info("Web vitals!")
// }

const MyApp = ({ Component, pageProps, router, admin, isProductionEnv, socials, err }) => {
	useEffect(() => {
		if (isProductionEnv)
			Router.onRouteChangeComplete = (url) => {
				trackPageView(url);
			};

		hotJarInitial(process.env.HOTJAR_ID, process.env.HOTJAR_SV);
	}, []);

	return (
		<>
			<DefaultSeo {...SEO} />
			<Navigation />
			<AnimatePresence>
				<Component
					{...pageProps}
					key={router.route}
					admin={admin}
					socials={socials}
					isProductionEnv={isProductionEnv}
					err={err}
				/>
			</AnimatePresence>
		</>
	);
};

MyApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext);

	logger.info(appContext.ctx.req.url);

	const admin = await getAdminPerson();

	// parse data
	const socials = admin.socials.map((social) => parseSocial(social));
	const isProductionEnv = isProduction();
	return {
		...appProps,
		isProductionEnv,
		admin,
		socials
	};
};

export default MyApp;
